<template>
  <div class="info_box">
    <div class="box">
      <div class="left">
        <img :src="$store.state.info.headImgUrl" alt="">
      </div>
      <div class="right">
        <div class="info"><p class="name">{{name == '' ? $store.state.info.nickName : name}}</p><span class="status" v-if="status">已录用</span></div>
        <p class="post">应聘岗位：{{post}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonalInfo",
  props: {
    post: {
      default: ''
    },
    name: {
      default: ''
    },
    status: {
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
  .info_box{
    .box{
      width: 95%;
      height: 110px;
      background: #02DBCA;
      border-radius: 7px;
      margin: 0 2.5%;
      display: flex;
      align-items: center;
      .left{
        padding-left: 24px;
        img{
          width: 66px;
          height: 66px;
          border-radius: 50%;
          border:3px solid #7bece3;
        }
      }
      .right{
        margin-left: 12px;
        .info{
          display: flex;
          align-items: center;
          .name{
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: normal;
            color: #FFFFFF;
          }
          .status{
            width: 52px;
            height: 20px;
            background: #FFFFFF;
            border-radius: 10px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #02DBCA;
            text-align: center;
            line-height: 20px;
            margin-left: 7px;
          }
        }
        .post{
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: normal;
          color: #FFFFFF;
          margin-top: 2px;
        }
      }
    }
  }
</style>
