import request from '../util/request'

//获取用户信息
export function GetUserInfo(data) {
    return request({
        url: '/api/XmhHr/ReturnUserInfo',
        method: 'get',
        params : data
    })
}

//获取用户面试（入职）状态
export function GetUserStatus(data){
    return request({
        url: '/api/XmhHr/CheckStatus',
        method: 'post',
        params : data
    })
}